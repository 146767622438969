import { gql } from 'urql/core';

export default gql`
query getUsers {
  users {
    id
    name
    email
    role
  }
}
`;
